export default {
    setToken(state, token) {
        state.token = token;
    },
    setPlayerName(state, name) {
        state.playerName = name;
    },
    setLang(state, lang) {
        state.lang = lang;
    },
};